<template>
  <!--begin::Card-->
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          API
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Autenticação & Documentação</span
        >
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <div v-if="haveEnterprise >= 0">
          <h1 class="text-h1">Token de acesso</h1>
          <p class="text-dark m-0 pt-5 font-weight-normal">
            Utilizar esse token no Authorization do Header das suas requisições.
          </p>
          <input type="hidden" id="codeHash" :value="hash" />
          <!-- <highlight-code lang="html" class="language-html"> -->
          <p class="text-dark-50 m-0 pt-5 font-weight-normal mb-5">
            {{ hash }}
          </p>
          <!-- </highlight-code> -->
          <span
            class="font-weight-bold font-size-lg mb-1 btn btn-info copy-btn ml-auto"
            @click.stop.prevent="copyCodeHash"
          >
            Copiar Token
          </span>

          <h1 class="text-h1 mt-10">Documentação</h1>

          <a
            class="font-weight-bold font-size-lg mb-1 btn btn-info"
            target="_blank"
            href="https://api.consensu.io/documentation"
          >
            api.consensu.io</a
          >
        </div>
        <div v-else>
          <div
            class="flex-grow-1 bg-danger p-12 pb-40 card-rounded flex-grow-1 bgi-no-repeat"
            style="background-position: calc(100% + 0.5rem) bottom; background-size: 35% auto; background-image: url(/media/svg/humans/custom-1.svg)"
          >
            <p
              class="text-inverse-danger pt-10 pb-5 font-size-h3 font-weight-bolder line-height-lg"
            >
              Área exclusiva para o plano: Enterprise
            </p>
            <p class="text-inverse-danger  font-size-h5">
              Você precisa realizar um upgrade de plano para ter acesso a esse
              recurso.
            </p>
            <router-link
              to="/plans"
              class="btn btn-lg btn-warning font-weight-bold mt-10"
              >Upgrade</router-link
            >
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "API",
  data() {
    return {
      plans: ["6000ba2a27f04f02fb4a72a8", "60ef8171184f45415007de22"]
    };
  },
  mounted() {},
  methods: {
    copyCodeHash() {
      let codeJS = document.querySelector("#codeHash");
      codeJS.setAttribute("type", "text"); // 不是 hidden 才能複製
      codeJS.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? "Token copiado com sucesso!"
          : "Token copiado com sucesso!";
        Swal.fire({
          text: msg,
          position: "top-end",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        // alert('Oops, unable to copy');
      }

      /* unselect the range */
      codeJS.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    hash() {
      return ApiService.generateToken({ user: this.currentUser._id });
    },
    haveEnterprise() {
      return this.plans.indexOf(this.currentUser.plan._id);
    }
  }
};
</script>
<style lang="scss"></style>
