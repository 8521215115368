<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end">
            <KTDropdown2></KTDropdown2>
          </div>
          <div class="d-flex align-items-center mb-10">
            <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <template v-if="picture">
                <img class="symbol-label" :src="picture" alt="" />
                <i class="symbol-badge bg-success"></i>
              </template>
              <template v-else>
                <span class="initials symbol-label">
                  {{ initials }}
                </span>
              </template>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
              >
                {{ fullname }}</a
              >
              <div class="text-muted">{{ currentUser.email }}</div>
            </div>
          </div>
          <!--end::User-->
          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
                ref="tab-0"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Informações pessoais</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
                ref="tab-1"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Communication/Shield-user.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Alterar Senha</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
                ref="tab-2"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Shopping/Dollar.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">Forma de pagamento</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor:pointer"
                data-tab="3"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
                ref="tab-3"
                v-b-tooltip.hover.right="'Novo'"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/General/Thunder-move.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">API</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a class="navi-link py-4" v-b-tooltip.hover.right="'Em Breve'">
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Text/Article.svg" />
                  </span>
                </span>
                <span class="navi-text">Configurações de Notificações</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" id="profile-tabs" v-model="tabIndex">
        <b-tab active>
          <KTPersonalInformation></KTPersonalInformation>
        </b-tab>

        <b-tab>
          <KTChangePassword></KTChangePassword>
        </b-tab>

        <b-tab>
          <CreditCard></CreditCard>
        </b-tab>
        <b-tab>
          <Api></Api>
        </b-tab>
        <b-tab>
          <KTEmailSettings></KTEmailSettings>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTDropdown2 from "@/view/content/dropdown/Dropdown2";
// import KTProfileOverview from "@/view/pages/profile/profile-comp-1/ProfileOverview";
import KTPersonalInformation from "@/view/pages/profile/profile-comp/PersonalInformation";
// import KTAccountInformation from "@/view/pages/profile/profile-comp/AccountInformation";
import KTChangePassword from "@/view/pages/profile/profile-comp/ChangePassword";
import KTEmailSettings from "@/view/pages/profile/profile-comp/EmailSettings";
import CreditCard from "@/view/pages/profile/profile-comp/CreditCard";
import Api from "@/view/pages/profile/profile-comp/Api";

export default {
  name: "custom-page",
  components: {
    Api,
    KTDropdown2,
    KTPersonalInformation,
    KTChangePassword,
    KTEmailSettings,
    CreditCard
  },
  data() {
    return {
      tabIndex: 0,
      alias: {
        "#infos": 0,
        "#password": 1,
        "#payment": 2,
        "#api": 3
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
    this.setTabByHash();
  },
  methods: {
    setTabByHash() {
      setTimeout(() => {
        let tab = this.alias[this.$route.hash] || 0;
        this.$refs[`tab-${tab}`].click();
      }, 100);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    fullname() {
      let name = this.currentUser?.name.split(" ") || "";
      return `${name[0]} ${name[1] || ""}`;
    },
    initials() {
      // //console.log();
      return this.fullname
        .match(/(^\S\S?|\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    },
    picture() {
      if (this.currentUser.avatar) {
        return process.env.BASE_URL + "media/users/300_21.jpg";
      }
      return false;
    }
  }
};
</script>
<style>
div#profile-tabs > div:first-child {
  display: none;
}
.initials {
  font-size: 24px;
}
</style>
